<template>
    <div class="orders-search-wrapper">
        <a-form
            class="search-wrapper"
            layout="inline"
            :form="form"
            @submit="handleSearch"
        >
            <!-- 订单号 -->
            <a-form-item
                label="订单号"
                class="form-item-wrapper"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-input
                    v-decorator="['orderNo', { initialValue: '' }]"
                    placeholder="按订单号搜索"
                />
            </a-form-item>

            <!-- 订单号 -->
            <a-form-item
                label="炳联码"
                class="form-item-wrapper"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-input
                    v-decorator="['machineNo', { initialValue: '' }]"
                    placeholder="按炳联码搜索"
                />
            </a-form-item>

            <!-- 用户手机 -->
            <a-form-item
                label="用户手机"
                class="form-item-wrapper"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-input
                    v-decorator="['userPhone', { initialValue: '' }]"
                    placeholder="按用户手机搜索"
                />
            </a-form-item>

            <!-- 状态 -->
            <a-form-item
                label="状态"
                class="form-item-wrapper status-form"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-select v-decorator="['status', { initialValue: '' }]">
                    <template v-for="item in status">
                        <a-select-option :value="item.value" :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>
                </a-select>
            </a-form-item>

            <!-- 商家 -->
            <a-form-item
                label="商家"
                class="form-item-wrapper status-form"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-select
                    placeholder="选择商家"
                    v-decorator="['business']"
                    show-search
                    option-filter-prop="children"
                    @search="business.onSearch"
                    @focus="business.onFocus"
                    @select="business.onSelect"
                >
                    <div slot="dropdownRender" slot-scope="menu">
                        <v-nodes :vnodes="menu" />

                        <div
                            v-if="!business.finished"
                            style="padding: 4px 8px; cursor: pointer"
                            @mousedown="(e) => e.preventDefault()"
                            @click="business.loadMore"
                        >
                            <a-icon type="plus" v-if="!business.loading" />
                            <a-spin v-else size="small" /> 加载更多
                        </div>
                    </div>

                    <template v-for="item in business.list">
                        <a-select-option :value="item.value" :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>
                </a-select>
            </a-form-item>

            <!-- 门店 -->
            <a-form-item
                label="门店"
                class="form-item-wrapper status-form"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-select
                    placeholder="选择门店"
                    v-decorator="['store']"
                    show-search
                    option-filter-prop="children"
                    @search="store.onSearch"
                    @focus="store.onFocus"
                >
                    <div slot="dropdownRender" slot-scope="menu">
                        <v-nodes :vnodes="menu" />

                        <div
                            v-if="!store.finished && store.list.length > 0"
                            style="padding: 4px 8px; cursor: pointer"
                            @mousedown="(e) => e.preventDefault()"
                            @click="store.loadMore"
                        >
                            <a-icon type="plus" v-if="!store.loading" />
                            <a-spin v-else size="small" /> 加载更多
                        </div>
                    </div>

                    <template v-for="item in store.list">
                        <a-select-option :value="item.value" :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>
                </a-select>
            </a-form-item>

            <!-- 设备名称 -->
            <a-form-item
                label="设备名称"
                class="form-item-wrapper"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-input
                    v-decorator="['machineName', { initialValue: '' }]"
                    placeholder="按设备名称搜索"
                />
            </a-form-item>

            <!-- 交易单号 -->
            <a-form-item
                label="交易单号"
                class="form-item-wrapper"
                :label-col="formCol.labelCol"
                :wrapper-col="formCol.wrapperCol"
            >
                <a-input
                    v-decorator="['payNo', { initialValue: '' }]"
                    placeholder="按交易单号搜索"
                />
            </a-form-item>

            <!-- 下单时间 -->
            <a-form-item label="下单时间"
                         class="form-item-wrapper form-time"
                         :label-col="formTime.labelCol"
                         :wrapper-col="formTime.wrapperCol">

                <a-range-picker v-decorator="['time']"
                                :show-time="{ format: 'HH:mm:ss' }"
                                format="YYYY-MM-DD HH:mm:ss"
                                :placeholder="['开始日期', '结束日期']" />

            </a-form-item>

            <!-- 查询/重置 -->
            <a-form-item class="form-item-wrapper">
                <a-button class="submit-btn" type="primary" html-type="submit">
                    查询
                </a-button>

                <a-button @click="resetForm"> 重置 </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import { getUsersList } from "network/usersManage";
import { getStoreList } from "network/store";

import { debounce } from "common/utils";

export default {
    name: "OrdersSearch", // 商家用户筛选
    components: {
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
            formCol: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            // formStatus: {
            //     labelCol: { span: 5 },
            //     wrapperCol: { span: 14 },
            // },
            formTime: {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
            },
            form: this.$form.createForm(this, { name: "ordersSearch" }),

            status: [
                {
                    name: "全部",
                    value: "",
                },
                {
                    name: "强制完结",
                    value: "-3",
                },
                {
                    name: "待确认",
                    value: "-2",
                },
                {
                    name: "已确认",
                    value: "-1",
                },
                {
                    name: "待支付",
                    value: "0",
                },
                {
                    name: "已完成",
                    value: "1",
                },
                {
                    name: "创建支付分订单失败",
                    value: "2",
                },
                {
                    name: "支付分扣费进行中",
                    value: "3",
                },
                {
                    name: "支付分已关闭",
                    value: "4",
                },
                {
                    name: "售后中",
                    value: "5",
                },
                {
                    name: "售后完成",
                    value: "6",
                },
                {
                    name: "售后驳回",
                    value: "7",
                },
            ],

            // 商家
            business: {
                page: 1,
                pageSize: 20,
                loading: false,
                finished: false,
                search: "",
                list: [],
                searchFn: debounce((value) => {
                    this.business.search = value;
                    this.business.page = 1;

                    this.business.loadData(this.business.page);
                }, 300),
                onSearch: (value) => this.business.searchFn(value),
                onFocus: () => {
                    // 当有搜索时，重新获取焦点重新加载列表
                    if (this.business.search) {
                        this.business.page = 1;
                        this.business.search = "";

                        this.business.loadData(this.business.page);
                    }
                },
                onSelect: (value) => {
                    // 选择了其他商家，重置门店选项及列表
                    this.form.resetFields(['store'])

                    this.store.page = 1;
                    this.store.loadData(this.store.page);
                },
                loadMore: () => {
                    if (!this.business.finished && !this.business.loading) {
                        let page = this.business.page + 1;

                        this.business.loadData(page);
                    }
                },
                // 加载商家列表
                loadData: (page) => {
                    let params = {
                        userType: "1",
                        operatorName: this.business.search,
                    };

                    this._getUsersList(page, this.business.pageSize, params);
                },
            },

            // 门店
            store: {
                page: 1,
                pageSize: 20,
                loading: false,
                finished: false,
                search: "",
                list: [],
                searchFn: debounce((value) => {
                    this.store.search = value;
                    this.store.page = 1;

                    this.loadStore(this.store.page);
                }, 300),
                init: () => {
                    this.store.list = [];
                    this.store.loading = false;
                    this.store.finished = false;
                },
                onSearch: (value) => this.store.searchFn(value),
                onFocus: () => {
                    // 当有搜索时，重新获取焦点重新加载列表
                    if (this.store.search) {
                        this.store.page = 1;
                        this.store.search = "";

                        this.store.loadData(this.store.page);
                    }
                },
                loadMore: () => {
                    if (!this.store.finished && !this.store.loading) {
                        let page = this.store.page + 1;

                        this.store.loadData(page);
                    }
                },
                // 加载商家列表
                loadData: (page) => {
                    let params = {
                        userId: this.form.getFieldValue('business'),
                        name: this.store.search,
                    };

                    this._getStoreList(page, this.store.pageSize, params);
                },
            },
        };
    },
    created() {
        // 首次加载商家列表
        this.business.loadData(this.business.page);
    },
    methods: {
        // ----------------事件处理函数----------------
        // 点击查询
        handleSearch(e) {
            e.preventDefault();
            const values = this.form.getFieldsValue();

            this.$emit("onSearch", values);
        },
        // 重置
        resetForm() {
            this.form.resetFields();

            const values = this.form.getFieldsValue();

            this.$emit("onSearch", values);

            this.store.init()
        },

        // -----------------网络请求-----------------
        // 获取用户列表
        _getUsersList(
            pageNumber,
            pageSize,
            params = {
                userType: "1",
                operatorName: "",
            }
        ) {
            this.business.loading = true;

            getUsersList(pageNumber, pageSize, params)
                .then((res) => {
                    this.business.page =
                        res.currentSize > 0 ? pageNumber : this.business.page;

                    let list = res.data;

                    let newList =
                        pageNumber != 1 ? [...this.business.list] : [];

                    class User {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let user = new User({
                            key: item.id,
                            value: item.id,
                            name: item.operatorName,
                        });

                        newList.push(user);
                    }

                    this.business.loading = false;
                    this.business.finished = res.currentSize < pageSize;
                    this.business.list = newList;
                })
                .catch((err) => {
                    this.business.loading = false;
                });
        },

        // 获取门店列表
        _getStoreList(pageNumber, pageSize, params = { userId: "", name: '' }) {
            this.store.loading = true;

            getStoreList(pageNumber, pageSize, params)
                .then((res) => {
                    const list = res.data;

                    this.store.page =
                        res.currentSize > 0 ? pageNumber : this.store.page;

                    class Store {
                        constructor(name, id, value) {
                            this.name = name;
                            this.id = id;
                            this.value = value;
                        }
                    }

                    let newList =
                        pageNumber != 1 ? [...this.store.list] : [];

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let store = new Store(item.name, item.id, item.id);
                        newList.push(store);
                    }

                    this.store.loading = false;
                    this.store.finished = res.currentSize < pageSize;
                    this.store.list = newList;
                })
                .catch((err) => {
                    this.store.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.orders-search-wrapper {
    width: 100%;

    .search-wrapper {
        width: 100%;

        .form-item-wrapper {
            width: 300px;

            .submit-btn {
                margin-right: 20px;
            }
        }

        .form-time {
            width: auto;
        }
    }
}
</style>