<template>
    <div id="orders-manage">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="orders-data">
            <!-- 筛选 -->
            <orders-search @onSearch="onSearch" />

            <!-- 操作按钮 -->
            <orders-btns
                :order-nos="orderNos"
                :search-obj="searchObj"
                @onSuccess="onSuccess"
            />

            <!-- 列表 -->
            <div class="orders-list-wrapper">
                <orders-list
                    :list="ordersList"
                    :check-keys.sync="checkKeys"
                    :loading="loading"
                />
            </div>

            <!-- 分页 -->
            <pagination
                :page.sync="page"
                :page-size="pageSize"
                :total-size="totalSize"
                @onPageChange="onPageChange"
                @onSizeChange="onSizeChange"
            />
        </div>
    </div>
</template>

<script>
import OrdersList from "./childComps/OrdersList";
import OrdersSearch from "./childComps/OrdersSearch";
import OrdersBtns from "./childComps/OrdersBtns.vue";

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Pagination from "components/pagination/Pagination";

import { getOrdersList } from "network/orders";

export default {
    name: "OrdersManage", // 订单管理
    components: {
        OrdersList,
        OrdersSearch,
        OrdersBtns,

        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            breadcrumb: [
                {
                    path: "",
                    breadcrumbName: "首页",
                },
                {
                    path: "/ordersManage",
                    breadcrumbName: "订单管理",
                },
            ],

            machineNo: "", // 炳联码
            phone: "", // 用户手机号
            orderNo: "", // 订单号
            status: "", // 状态
            business: "", // 商家
            store: "", // 门店
            machineName: "", // 设备名称
            payNo: "", // 交易单号
            startTime: "", // 开始时间
            endTime: "", // 结束时间

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            ordersList: [],
            checkKeys: [],

            showModal: false, // 是否显示创建/编辑弹窗
        };
    },
    computed: {
        // 订单号列表
        orderNos() {
            let list = [];

            this.ordersList.forEach((item) => {
                if (this.checkKeys.indexOf(item.id) >= 0) {
                    list.push(item.orderNo);
                }
            });

            return list;
        },

        // 搜索条件
        searchObj() {
            let obj = {
                deviceName: this.machineName,
                deviceNo: this.machineNo,
                orderNo: this.orderNo,
                orderStatus: this.status,
                orderTimeEnd: this.endTime,
                orderTimeStart: this.startTime,
                storeId: this.store,
                storeUserId: this.business,
                userPhone: this.phone,
                wechatOrderId: this.payNo,
            };

            return obj;
        },
    },
    created() {
        this._getOrdersList(this.page, this.pageSize);
    },
    methods: {
        // -------------------事件处理函数-------------------
        // 查询
        onSearch(values) {
            this.machineNo = values.machineNo || "";
            this.orderNo = values.orderNo || "";
            this.status = values.status || "";
            this.phone = values.userPhone || "";
            this.business = values.business || "";
            this.store = values.store || "";
            this.machineName = values.machineName || "";
            this.payNo = values.payNo || "";

            if (values.time) {
                this.startTime = values.time[0].format("YYYY-MM-DD HH:mm:ss");
                this.endTime = values.time[1].format("YYYY-MM-DD HH:mm:ss");
            } else {
                this.startTime = "";
                this.endTime = "";
            }

            this.page = 1;

            this.loadData();
        },
        // 切换页码
        onPageChange(page) {
            this.page = page;

            this.loadData();
        },

        // 切换一页多少条
        onSizeChange(pageSize) {
            this.pageSize = pageSize;

            this.loadData();
        },

        // 成功
        onSuccess() {
            this.loadData();
        },
        loadData() {
            let params = {
                deviceNo: this.machineNo,
                orderNo: this.orderNo,
                orderStatus: this.status,
                userPhone: this.phone,
                deviceName: this.machineName,
                storeUserId: this.business,
                storeId: this.store,
                wechatOrderId: this.payNo,
                orderTimeStart: this.startTime,
                orderTimeEnd: this.endTime,
            };

            this._getOrdersList(this.page, this.pageSize, params);
        },

        // -----------------事件处理函数---------------
        // 订单列表
        _getOrdersList(
            pageNumber,
            pageSize,
            params = {
                deviceNo: "",
                orderNo: "",
                orderStatus: "",
                userPhone: "",
            }
        ) {
            this.loading = true;

            getOrdersList(pageNumber, pageSize, params)
                .then((res) => {
                    this.totalSize = res.totalSize;
                    this.page = res.currentSize > 0 ? pageNumber : this.page;

                    const list = res.data;

                    let newList = [];

                    class Order {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let goodsArr = [];
                        item.goodsList.forEach((goods) => {
                            let name = `${goods.goodsName} x${goods.goodsAmount}`;
                            goodsArr.push(name);
                        });

                        let status = "";

                        switch (item.orderStatus) {
                            case -3:
                                status = "强制完结";
                                break;
                            case -2:
                                status = "待确认";
                                break;
                            case -1:
                                status = "已确认";
                                break;
                            case 0:
                                status = "待支付";
                                break;
                            case 1:
                                status = "已完成";
                                break;
                            case 2:
                                status = "创建支付分订单失败";
                                break;
                            case 3:
                                status = "支付分扣费进行中";
                                break;
                            case 4:
                                status = "支付分已关闭";
                                break;
                            case 5:
                                status = "售后中";
                                break;
                            case 6:
                                status = "售后完成";
                                break;
                            case 7:
                                status = "售后驳回";
                                break;
                        }

                        let order = new Order({
                            goods: goodsArr.join("，"),
                            status,
                            business: item.storeUserName,
                            businessName: item.storeOperatorName,
                            machine: item.deviceNo,
                            machineName: item.deviceName,
                            storeName: item.storeName,
                            payNo: item.wechatOrderId,
                            isAbnormal: item.orderWeightStatus,
                            ...item,
                        });

                        for (const key in order) {
                            !order[key] &&
                                order[key] != 0 &&
                                (order[key] = "暂无数据");
                        }

                        newList.push(order);
                    }

                    this.loading = false;
                    this.ordersList = newList;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
#orders-manage {
    width: 100%;
    position: relative;

    .orders-data {
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;

        .orders-list-wrapper {
            width: 100%;
            margin: 20px auto;
            overflow: auto;
        }
    }
}
</style>