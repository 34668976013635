import api from './api'
import { request } from './request'

// 订单列表
function getOrdersList(pageNumber, pageSize, params = {
    deviceNo: '',
    orderNo: '',
    orderStatus: '',
    userPhone: ''
}) {
    return request({
        url: api.orders.list,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 取消订单
function cancelOrders(orderNo) {
    return request({
        url: api.orders.cancelOrders + `/${ orderNo }`,
        method: 'post'
    })
}

// 催单
function reminberOrders(orderNo) {
    return request({
        url: api.orders.reminderOrders + `/${ orderNo }`,
        method: 'post'
    })
}

// 导出订单
function exportOrders(params) {
    return request({
        url: api.orders.exportOrders,
        method: 'post',
        responseType: 'blob',
        timeout: 60000,
        data: params
    })
}

// 获取订单详情信息
function getOrderDetail(id) {
    return request({
        url: `${ api.orders.detail }/${ id }`,
        method: 'get'
    })
}

export {
    getOrdersList,
    cancelOrders,
    reminberOrders,
    exportOrders,
    getOrderDetail
}