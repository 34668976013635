<template>
    <div class="orders-list" ref="listBox">
        <a-table
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :loading="loading"
            :scroll="scroll"
            :row-key="record => record.id"
            :row-selection="{ selectedRowKeys: checkKeys, onChange: onSelectChange }"
        >

            <div slot="abnormal" slot-scope="record">
                <div :class="{ 'is-abnormal': record == 0 }">{{ record == 0 ? '异常' : '正常' }}</div>
            </div>

            <div slot="action" slot-scope="record">
                <a class="check-btn" href="javascript:;" @click="goIntoOrderDetail(record)">查看详情</a>
            </div>

        </a-table>
    </div>
</template>

<script>
export default {
    name: "OrdersList", // 设备列表
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        checkKeys: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    title: "订单号",
                    dataIndex: "orderNo",
                    key: "orderNo",
                    width: 130,
                    fixed: "left",
                },
                {
                    title: "商品",
                    dataIndex: "goods",
                    key: "goods",
                    width: 130,
                },
                {
                    title: "订单状态",
                    dataIndex: "status",
                    key: "status",
                    width: 130,
                },
                {
                    title: "异常状态",
                    dataIndex: "isAbnormal",
                    key: "isAbnormal",
                    width: 130,
                    scopedSlots: { customRender: "abnormal" },
                },
                {
                    title: "订单金额(元)",
                    dataIndex: "orderAmount",
                    key: "orderAmount",
                    width: 130,
                },
                {
                    title: "实付金额(元)",
                    dataIndex: "payAmount",
                    key: "payAmount",
                    width: 130,
                },
                {
                    title: "用户手机",
                    dataIndex: "userPhone",
                    key: "userPhone",
                    width: 130,
                },
                {
                    title: "商家账号",
                    dataIndex: "business",
                    key: "business",
                    width: 130,
                },
                {
                    title: "商家名称",
                    dataIndex: "businessName",
                    key: "businessName",
                    width: 130,
                },
                {
                    title: "门店名称",
                    dataIndex: "storeName",
                    key: "storeName",
                    width: 130,
                },
                {
                    title: "炳联码",
                    dataIndex: "machine",
                    key: "machine",
                    width: 130,
                },
                {
                    title: "设备名称",
                    dataIndex: "machineName",
                    key: "machineName",
                    width: 130,
                },
                {
                    title: "交易单号",
                    dataIndex: "payNo",
                    key: "payNo",
                    width: 150,
                },
                {
                    title: "下单时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: 150,
                },
                {
                    title: "支付时间",
                    dataIndex: "payTime",
                    key: "payTime",
                    width: 150,
                },
                {
                    title: "操作",
                    key: "operation",
                    fixed: "right",
                    width: 130,
                    align: "center",
                    scopedSlots: { customRender: "action" },
                },
            ],
            scroll: {
                x: true,
                y: 480,
            },
        };
    },
    methods: {
        onSelectChange(selectedRowKeys) {
            this.$emit('update:checkKeys', selectedRowKeys)
        },

        // 进入订单详情
        goIntoOrderDetail({ id }) {
            this.$router.push({ name: 'orderDetail', query: { id } })
        }
    }
};
</script>

<style lang="less" scoped>
.orders-list {
    width: 100%;
    // height: 100%;
    overflow: auto;

    .is-abnormal {
        color: red;
    }

    .btn {
        margin-right: 20px;
    }

    .check-btn {
        color: var(--color-tint);
    }
}
</style>