import api from './api'
import { request } from './request'

// 门店列表
function getStoreList(pageNumber, pageSize, params = {
    name: '',
    userId: ''
}) {
    return request({
        url: api.store.list,
        method: 'post',
        data: {
            pageNumber, pageSize, params
        }
    })
}

export {
    getStoreList
}