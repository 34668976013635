<template>
    <div class="orders-btns">
        <a-button class="btn" :loading="loading" type="primary" @click="handleCancel">取消订单</a-button>
        <a-button class="btn" :loading="loading" type="primary" @click="handleReminder">催收订单</a-button>
        <a-button class="btn" :loading="loading" type="primary" @click="handleExport">导出订单</a-button>
    </div>
</template>

<script>
import { cancelOrders, reminberOrders, exportOrders } from 'network/orders'

import { blobDownload } from "common/blobDownload";

export default {
    name: "OrdersBtns", // 设备管理操作按钮
    props: {
        orderNos: {
            type: Array,
            default() {
                return []
            }
        },
        searchObj: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        // ---------------事件处理函数------------------
        // 取消订单
        handleCancel() {
            if(this.orderNos.length <= 0) return

            let orderNo = this.orderNos.join(',');
            this._cancelOrders(orderNo)
        },
        // 催单
        handleReminder() {
            if(this.orderNos.length <= 0) return

            let orderNo = this.orderNos.join(',')
            this._reminberOrders(orderNo)
        },
        // 导出
        handleExport() {
            // if(this.orderNos.length <= 0) return

            let params = this.searchObj

            this._exportOrders(params)
        },

        // ----------------网络请求---------------
        // 取消订单
        _cancelOrders(orderNo) {
            this.loading = true

            cancelOrders(orderNo).then(res => {
                this.$message.success(res.message);
                
                this.$emit('onSuccess')
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },

        // 催单
        _reminberOrders(orderNo) {
            this.loading = true

            reminberOrders(orderNo).then(res => {
                this.$message.success(res.message);
                
                this.$emit('onSuccess')
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },

        // 导出订单
        _exportOrders(params) {
            this.loading = true

            exportOrders(params).then(res => {
                // 下载
                blobDownload(res, "orders.xlsx", 'application/vnd.ms-excel');
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },
    },
};
</script>

<style lang="less" scoped>
.orders-btns {
    width: 100%;
    margin: 20px auto;

    .btn {
        margin-right: 20px;
    }
}
</style>